import { CANDIDATE_FIELDS } from '../../constants/candidateDataConfig';
import { dateValidator } from '../timeHelpers';
import { keys, isEmpty } from 'lodash';

const getCandidatesCsvGeneratedJsonErrors = (candidates) => {
  const errors = new Set();

  // Check for zero candidates
  if (candidates.length === 0) {
    errors.add('Your file contains no rows of candidates');
    return Array.from(errors);
  }

  // Check for missing headers
  const headersPresent = keys(candidates[0]);

  const headersMap = {};
  headersPresent.forEach((header) => {
    headersMap[header] = true;
  });

  for (const requiredHeader of CANDIDATE_FIELDS) {
    if (!headersMap[requiredHeader]) {
      errors.add(`Your file is missing the '${requiredHeader}' header`);
    }
  }

  if (!isEmpty(errors)) {
    return Array.from(errors);
  }

  candidates.forEach((candidate) => {
    // Check for empty required fields
    for (const field of CANDIDATE_FIELDS) {
      if (!candidate[field]) {
        errors.add(
          `Your file has at least one row that is missing the '${field}' field`
        );
      }
    }

    if (isNaN(candidate.classId)) {
      errors.add(
        'Your file has at least one "classId" value that is not a number'
      );
    }

    if (!dateValidator(candidate.dayOne)) {
      errors.add(
        `Day one '${candidate.dayOne}' is not in correct format: MM/DD/YYYY`
      );
    }
  });

  return Array.from(errors);
};

export default getCandidatesCsvGeneratedJsonErrors;
