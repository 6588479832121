import React, { useState, useEffect } from 'react';
import { Button, Modal } from '@amzn/awsui-components-react/polaris';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { editCandidateApi } from '../../actions/candidates';
import {
  FormField,
  Input,
  DatePicker,
} from '@amzn/awsui-components-react/polaris';
import {
  EDITABLE_FIELDS,
  EDITABLE_FIELDS_LABEL,
  EDITABLE_FIELDS_WITH_WARNING,
  EDITABLE_FIELDS_WARNINGS,
} from '../../../constants/candidateDataConfig';
import moment from 'moment';
import { get } from 'lodash';
import '../../styles/editCandidateModal.scss';
import { YYYY_MM_DD } from '../../../constants/timeFormats.js';
const EditCandidateModal = ({
  selectedCandidate,
  isModalVisible,
  setIsModalVisible,
}) => {
  const [editedCandidate, setEditedCandidate] = useState(selectedCandidate);
  const [focusedElementId, setFocusedElementId] = useState('');
  const [warningVisible, setWarningVisible] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setEditedCandidate(selectedCandidate);
  }, [selectedCandidate]);

  useEffect(() => {
    if (EDITABLE_FIELDS_WITH_WARNING.includes(focusedElementId)) {
      setWarningVisible(true);
    } else {
      setWarningVisible(false);
    }
  }, [focusedElementId]);

  const onClose = () => {
    setEditedCandidate(selectedCandidate);
    setIsModalVisible(false);
  };
  const onSubmit = () => {
    dispatch(editCandidateApi(editedCandidate));
    setIsModalVisible(false);
  };
  const modalHeaderText = 'Edit Candidate';
  const modalCancelText = 'Cancel';
  const modalSubmitText = 'Ok';
  return (
    <>
      <Modal
        onDismiss={onClose}
        visible={isModalVisible}
        size="medium"
        footer={
          <>
            <Button onClick={onClose}>{modalCancelText}</Button>
            <Button variant="primary" onClick={onSubmit}>
              {modalSubmitText}
            </Button>
          </>
        }
        header={modalHeaderText}
      >
        <Alert
          onDismiss={() => setWarningVisible(false)}
          visible={warningVisible}
          type="warning"
        >
          {EDITABLE_FIELDS_WARNINGS[focusedElementId] || ''}
        </Alert>
        <div id="editCandidateForm">
          {EDITABLE_FIELDS.map((element) => {
            if (element.type === 'date') {
              return (
                <FormField
                  label={EDITABLE_FIELDS_LABEL[element.key]}
                  key={element.key}
                >
                  <DatePicker
                    placeholder={element.format}
                    value={moment(
                      get(editedCandidate, [element.key], '').toString(),
                      element.format
                    ).format('YYYY-MM-DD')}
                    todayLabel="Today"
                    nextMonthLabel="Next month"
                    previousMonthLabel="Previous month"
                    onChange={({ detail }) => {
                      setEditedCandidate({
                        ...editedCandidate,
                        [element.key]: moment(detail.value, YYYY_MM_DD).format(
                          element.format
                        ),
                      });
                    }}
                  />
                </FormField>
              );
            } else {
              return (
                <FormField
                  label={EDITABLE_FIELDS_LABEL[element.key]}
                  key={element.key}
                >
                  <Input
                    id={element.key}
                    value={get(editedCandidate, [element.key], '').toString()}
                    onChange={(val) => {
                      setEditedCandidate({
                        ...editedCandidate,
                        [element.key]: val.detail.value,
                      });
                    }}
                    onFocus={(element) =>
                      element && setFocusedElementId(element.srcElement.id)
                    }
                  />
                </FormField>
              );
            }
          })}
        </div>
      </Modal>
    </>
  );
};
EditCandidateModal.propTypes = {
  selectedCandidate: PropTypes.object,
  isModalVisible: PropTypes.bool,
  setIsModalVisible: PropTypes.func,
};
export default EditCandidateModal;
