import React, { useState, useEffect } from 'react';
import EnhancedTable from '../components/EnhancedTable';
import TableHeader from '../components/TableHeader';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ExpandableSection } from '@amzn/awsui-components-react';
import DeleteCandidateModal from '../components/candidateManager/DeleteCandidateModal';
import EditCandidateModal from '../components/candidateManager/EditCandidateModal';
import UploadCsvFileModal from '../components/UploadCsvFileModal';
import { getCandidatesApi, uploadCandidatesApi } from '../actions/candidates';
import csvFileRequirements from '../../constants/candidatesCSVFIleRequirements';
import getCandidatesCsvGeneratedJsonErrors from '../../helpers/candidateParsing/getCandidatesCsvJsonErrors';
import transformCandidatesCsvJsonToApiJson from '../../helpers/candidateParsing/transformCandidatesCsvJsonToApiJson';
import {
  CANDIDATE_FIELDS,
  CANDIDATE_FIELDS_HEADER_MAPPING,
} from '../../constants/candidateDataConfig';
import { clearAllBannerMessages } from '../actions/bannerMessages';
import { dateComparator } from '../../helpers/tableComparators.js';
import csvFile from '../assets/candidate_upload_template.csv';

const CandidateManagerPage = () => {
  const [currentSelection, setCurrentSelection] = useState([]);
  const [
    isUploadCandidatesModalVisible,
    setIsUploadCandidatesModalVisible,
  ] = useState(false);
  const [
    isDeleteCandidateModalVisible,
    setIsDeleteCandidateModalVisible,
  ] = useState(false);
  const [
    isEditCandidateModalVisible,
    setIsEditCandidateModalVisible,
  ] = useState(false);
  const candidates = useSelector((state) => state.candidates);
  const candidatesLoading = useSelector(
    (state) => state.loading.candidatesLoading
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearAllBannerMessages());
    dispatch(getCandidatesApi());
  }, []);
  const headers = CANDIDATE_FIELDS.map(
    (field) => CANDIDATE_FIELDS_HEADER_MAPPING[field]
  );
  const specialSortableColumnsComparatorsObj = {
    dayOne: dateComparator('dayOne'),
  };

  const getCandidateTemplateURL = () => {
    // CSV default encoding differs on Mac & Windows when used with Excel
    const platform = window.navigator.platform;
    const macPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    if (macPlatforms.includes(platform)) {
      return 'https://test-schedule-finder-ui.s3.us-east-2.amazonaws.com/assets/mac/upload_candidate_template.csv';
    } else {
      return 'https://test-schedule-finder-ui.s3.us-east-2.amazonaws.com/assets/upload_candidate_template.csv';
    }
  };

  const HeaderComponent = () => (
    <TableHeader
      title="Candidate Manager"
      onRefresh={() => dispatch(getCandidatesApi())}
      buttons={[
        <Button
          key="deleteCandidate"
          disabled={currentSelection.length === 0}
          onClick={() => {
            setIsDeleteCandidateModalVisible(true);
          }}
        >
          Delete
        </Button>,
        <Button
          key="editCandidate"
          disabled={currentSelection.length === 0}
          onClick={() => {
            setIsEditCandidateModalVisible(true);
          }}
        >
          Edit
        </Button>,
        <Button
          key="uploadCandidatesCSV"
          variant="primary"
          onClick={() => setIsUploadCandidatesModalVisible(true)}
        >
          Upload Candidates CSV
        </Button>,
      ]}
    />
  );
  const UploadCandidateModalDescription = () => (
    <div>
      <div>
        Click the &apos;Choose File&apos; button below and select a file to be
        uploaded. Uploaded files must be in a .CSV format. If you are unsure how
        the file should look, please{' '}
        <a href={csvFile} download="candidate_upload_template.csv">
          download the candidate template
        </a>
        .
      </div>
    </div>
  );

  const UploadCandidateModalRequirements = () => (
    <div className="awsui-util-action-stripe-group">
      <ExpandableSection header="Upload Requirements">
        <div>
          <div>
            Your uploaded candidate list must include the following columns that
            are important to track candidates as they move through the
            scheduling process:
          </div>
          <ul>
            {Object.keys(csvFileRequirements).map((key) => (
              <li key={key}>
                <div>
                  {key} - {csvFileRequirements[key]}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </ExpandableSection>
    </div>
  );
  return (
    <>
      <EnhancedTable
        headers={headers}
        dataKeys={CANDIDATE_FIELDS}
        data={candidates}
        loading={candidatesLoading}
        loadingText="Loading candidates"
        enableAction={true}
        currentSelection={currentSelection}
        setCurrentSelection={setCurrentSelection}
        HeaderComponent={HeaderComponent}
        specialSortableColumnsComparatorsObj={
          specialSortableColumnsComparatorsObj
        }
      />
      <DeleteCandidateModal
        selectedCandidate={currentSelection[0]}
        isModalVisible={isDeleteCandidateModalVisible}
        setIsModalVisible={setIsDeleteCandidateModalVisible}
      />
      <EditCandidateModal
        selectedCandidate={currentSelection[0]}
        isModalVisible={isEditCandidateModalVisible}
        setIsModalVisible={setIsEditCandidateModalVisible}
      />
      <UploadCsvFileModal
        title="Upload Candidates"
        Description={UploadCandidateModalDescription}
        Requirements={UploadCandidateModalRequirements}
        fileValidator={getCandidatesCsvGeneratedJsonErrors}
        uploadFile={(candidatesJson) => {
          dispatch(uploadCandidatesApi(candidatesJson));
        }}
        dataParser={transformCandidatesCsvJsonToApiJson}
        isModalVisible={isUploadCandidatesModalVisible}
        setIsModalVisible={setIsUploadCandidatesModalVisible}
      />
    </>
  );
};

export default CandidateManagerPage;
