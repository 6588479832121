const REQUIREMENTS = {
  'Class ID':
    'Match this column with the Class ID of the schedule in Schedule Manager',
  'First Name': 'First name of the candidate.',
  'Last Name': 'Last name of the candidate.',
  'Email Address': 'Email address of the candidate.',
  'Candidate ID':
    'This is usually the Salesforce ID but could be any unique identifying number.',
  'Day One': 'This is the day the candidate will start.',
  Language:
    'This is the language the candidate will be supporting. Use 2 letter acronyms from this list:',
  OU: 'This is the OU the candidate will be assigned to.',
  Site: 'This is your location (VCSNA, WIN, PSC, HTS, etc.)',
};

export default REQUIREMENTS;
