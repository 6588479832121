const transformCandidatesCsvJsonToApiJson = (json) => {
  return json.map((candidate) => {
    return {
      ...candidate,
      classId: Number(candidate.classId),
    };
  });
};

export default transformCandidatesCsvJsonToApiJson;
