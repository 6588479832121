import {
  UPDATE_CANDIDATE,
  CANDIDATES_ARE_LOADING,
  ADD_CANDIDATES,
  UPDATE_CANDIDATES,
} from './types';
import {
  EDIT_CANDIDATE_ENDPOINT,
  DELETE_CANDIDATE_ENDPOINT,
  ADD_CANDIDATES_ENDPOINT,
  GET_CANDIDATES_ENDPOINT,
  headers,
} from '../../constants/apiConfigs';
import { validationErrorGenerator } from '../../helpers/validationErrorHelper';
import axios from 'axios';
import { get } from 'lodash';
import { showSuccessBanner, showErrorBanner } from './bannerMessages';

export function updateCandidate(candidate) {
  return {
    type: UPDATE_CANDIDATE,
    payload: candidate,
  };
}

export function updateCandidates(candidates) {
  return {
    type: UPDATE_CANDIDATES,
    payload: candidates,
  };
}

export function addCandidates(candidates) {
  return {
    type: ADD_CANDIDATES,
    payload: candidates,
  };
}

export function candidatesAreLoading(isLoading) {
  return {
    type: CANDIDATES_ARE_LOADING,
    payload: isLoading,
  };
}

/* API calls */

export function editCandidateApi(candidate) {
  return async (dispatch) => {
    dispatch(candidatesAreLoading(true));
    return axios
      .post(
        EDIT_CANDIDATE_ENDPOINT,
        {
          Candidate: { ...candidate, classId: Number(candidate.classId) },
        },
        { headers: await headers() }
      )
      .then((res) => {
        if (res.status !== 200) {
          throw Error(res.statusText);
        }
        return res;
      })
      .then(() => {
        dispatch(getCandidatesApi());
        dispatch(
          showSuccessBanner(
            `Successfully Update candidate ${candidate.firstName} ${candidate.lastName}`
          )
        );
      })
      .catch((e) => {
        dispatch(
          showErrorBanner(
            `Failed to update candidate ${candidate.firstName} ${
              candidate.lastName
            }. Detail from server: ${get(
              e,
              'response.data.message',
              e.message
            )} `
          )
        );
      })
      .then(() => dispatch(candidatesAreLoading(false)));
  };
}

export function deleteCandidateApi(candidate) {
  const url =
    DELETE_CANDIDATE_ENDPOINT + '?candidateId=' + candidate.candidateId;
  const parameter = { candidateId: candidate.candidateId };
  return async (dispatch) => {
    dispatch(candidatesAreLoading(true));
    axios
      .delete(url, { headers: await headers(), parameter })
      .then((res) => {
        if (res.status !== 200) {
          throw Error(res.statusText);
        }
        dispatch(getCandidatesApi());
        dispatch(
          showSuccessBanner(
            `Candidate ${candidate.firstName} ${candidate.lastName} with Id: ${candidate.candidateId} was successfully deleted`
          )
        );
        return res;
      })
      .catch((error) => {
        dispatch(
          showErrorBanner(
            `There was an error deleting candidate with ID ${candidate.candidateId} in the database. ${error.response.data.message}`
          )
        );
      })
      .then(() => dispatch(candidatesAreLoading(false)));
  };
}

export const uploadCandidatesApi = (candidates) => {
  return async (dispatch) => {
    dispatch(candidatesAreLoading(true));
    return axios
      .post(
        ADD_CANDIDATES_ENDPOINT,
        {
          Candidates: candidates,
        },
        { headers: await headers() }
      )
      .then((res) => {
        if (res.status !== 200) {
          dispatch(showErrorBanner(res.message));
        }
        return res;
      })
      .then(() => {
        dispatch(getCandidatesApi());
        dispatch(
          showSuccessBanner(
            `Successfully added ${candidates.length} new candidate${
              candidates.length === 1 ? '' : 's'
            }`
          )
        );
      })
      .catch((e) => {
        dispatch(
          showErrorBanner(
            `Failed to add candidate(s). Detail from server: ${validationErrorGenerator(
              get(e, 'response.data.message', e.message)
            )} `
          )
        );
      })
      .then(() => dispatch(candidatesAreLoading(false)));
  };
};

export function getCandidatesApi() {
  return async (dispatch) => {
    dispatch(candidatesAreLoading(true));
    return axios
      .get(GET_CANDIDATES_ENDPOINT, {
        headers: await headers(),
      })
      .then((res) => {
        if (res.status !== 200) {
          dispatch(
            showErrorBanner(
              `Failed to retrieve candidate(s). Detail from server: ${get(
                res,
                'message',
                'None'
              )} `
            )
          );
        }
        return res;
      })
      .then((res) => dispatch(addCandidates(get(res, 'data.Candidates', []))))
      .catch((e) => {
        dispatch(
          showErrorBanner(
            `Failed to retrieve candidate(s). Detail from server: ${get(
              e,
              'response.data.message',
              e.message
            )} `
          )
        );
      })
      .then(() => dispatch(candidatesAreLoading(false)));
  };
}
