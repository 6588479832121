import React, { useState, useEffect } from 'react';
import { Button, Modal } from '@amzn/awsui-components-react/polaris';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormField } from '@amzn/awsui-components-react/polaris';
import { deleteCandidateApi } from '../../actions/candidates';
import {
  EDITABLE_FIELDS,
  EDITABLE_FIELDS_LABEL,
} from '../../../constants/candidateDataConfig';
import { get } from 'lodash';
import '../../styles/deleteCandidateModal.scss';

const DeleteCandidateModal = ({
  selectedCandidate,
  isModalVisible,
  setIsModalVisible,
}) => {
  const [deletedCandidate, setDeletedCandidate] = useState(selectedCandidate);
  const [deleteConfirmation, setDeleteConfirmation] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    setDeletedCandidate(selectedCandidate);
  }, [selectedCandidate]);

  const onClose = () => {
    setDeletedCandidate(selectedCandidate);
    setDeleteConfirmation('');
    setIsModalVisible(false);
  };
  const onSubmit = () => {
    console.log(deletedCandidate);
    dispatch(deleteCandidateApi(deletedCandidate));
    setIsModalVisible(false);
  };

  const modalHeaderText = 'Are you sure you want to delete this candidate?';
  const modalCancelText = 'Cancel';
  const modalSubmitText = 'Delete';

  return (
    <>
      <Modal
        onDismiss={onClose}
        visible={isModalVisible}
        size="medium"
        footer={
          <div className="awsui-util-f-r">
            <Button onClick={onClose}>{modalCancelText}</Button>
            <Button
              variant="primary"
              onClick={onSubmit}
              disabled={deleteConfirmation === modalSubmitText ? false : true}
            >
              {modalSubmitText}
            </Button>
          </div>
        }
        header={modalHeaderText}
      >
        <div id="deleteCandidateForm">
          {EDITABLE_FIELDS.map((element) => {
            return (
              <FormField
                label={EDITABLE_FIELDS_LABEL[element.key]}
                key={element.key}
              >
                <div className="delete-field-space">
                  <strong>
                    {get(deletedCandidate, [element.key], '').toString()}
                  </strong>
                </div>
              </FormField>
            );
          })}
        </div>

        <hr className="solid" />

        <FormField id="deleteConfirmationForm">
          <div className="delete-description-space">
            To confirm deletion of candidate, type <strong>Delete</strong> in
            the field
          </div>
          <input
            placeholder="Delete"
            className="delete-input-width"
            value={deleteConfirmation}
            onChange={(val) => {
              setDeleteConfirmation(val.target.value);
            }}
          />
        </FormField>
      </Modal>
    </>
  );
};

DeleteCandidateModal.propTypes = {
  selectedCandidate: PropTypes.object,
  isModalVisible: PropTypes.bool,
  setIsModalVisible: PropTypes.func,
};
export default DeleteCandidateModal;
